<template>
    <div class="product-wrapper">
     <search :logo="webset.logo"  :webset="webset"  />
        <div class="product-info">
            <div class="w1200 content" v-if="productInfo">
                <div class="left">
                    <el-carousel height="400px">
                        <el-carousel-item v-for="(item,index) in productInfo.slider.list" :key="index">
                            <img :src="item.value" class="bannerimg" />
                        </el-carousel-item>
                    </el-carousel>
                    <h1 v-if="uniacid == 29" style="margin-top: 20px; font-size: 25px;">
    <a href="http://sincere.mailserverhk.com/" target="_blank">{{$t('360 viewer')}}</a>
</h1>

                </div>
                <div class="right">
                    <h6 class="title">{{productInfo.basic.field.goods_name}}</h6>
                    
                    <div class="price flex-start">
                        <template v-if="currency == 'USD'">
                            <span>US${{productInfo.basic.field.us_price}}</span>
                            <span><!--US${{productInfo.basic.field.us_market_price}}--></span>
                        </template>
                        <template v-else>
                            <span>{{productInfo.basic.field.danwei+productInfo.basic.field.shop_price}}</span>
                            <span v-if="uniacid == 20 || uniacid == 33">{{productInfo.basic.field.danwei+productInfo.basic.field.market_price}}</span>
                        </template>
                    </div>
                    <div class="number flex-start">
                        <span>{{$t('number')}}：</span>
                        <div class="box flex-start">
                            <span class="reduce" @click="changeNumber('reduce')">-</span>
                            <span class="input">{{number}}</span>
                            <span class="add" @click="changeNumber('add')">+</span>
                        </div>
                    </div>


                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0022'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0022'">
                                <option :value="$t('Green')">{{$t('Green')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0023'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0023'">
                                <option :value="$t('White')">{{$t('White')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0009'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0009'">
                                <option :value="$t('Rice')">{{$t('Rice')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0020'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0020'">
                                <option  :value="$t('Silver')">{{$t('Silver')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0021'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0021'">
                                <option  :value="$t('Black')">{{$t('Black')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE-0011'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE-0011'">
                                <option  :value="$t('Blue')">{{$t('Blue')}}</option>
                                <option  :value="$t('Orange')">{{$t('Orange')}}</option>
                                <option  :value="$t('Gray')">{{$t('Gray')}}</option>
                                <option  :value="$t('Navy')">{{$t('Navy')}}</option>
                                <option  :value="$t('Black')">{{$t('Black')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0012'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0012'">
                                <option  :value="$t('Red')">{{$t('Red')}}</option>
                                <option  :value="$t('Purple')">{{$t('Purple')}}</option>
                                <option  :value="$t('Gray')">{{$t('Gray')}}</option>
                                <option  :value="$t('Black')">{{$t('Black')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0013'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0013'">
                                <option  :value="$t('Black')">{{$t('Black')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0014'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0014'">
                                <option  :value="$t('Black')">{{$t('Black')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0015'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0015'">
                                <option  :value="$t('Navy')">{{$t('Navy')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0017'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId === 'ACE_0017'">
                            <option :value="$t('Black')">{{ $t('Black') }}</option>
                        </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0018'">
                        <input type="hidden" id="color-select" ref="colorselect" class="form-control" value="">
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0019'">
                        <input type="hidden" id="color-select" ref="colorselect" class="form-control" value="">
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0001'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0001'">
                                <option  :value="$t('Black')">{{$t('Black')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0002'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0002'">
                                <option  :value="$t('Pink')">{{$t('Pink')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0003'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0003'">
                                <option  :value="$t('Black')">{{$t('Black')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0004'">
                        <input type="hidden" id="color-select" ref="colorselect" class="form-control" value="">
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0005'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0005'">
                                <option  :value="$t('Gray')">{{$t('Gray')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0006'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0006'">
                                <option  :value="$t('Pink')">{{$t('Pink')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0007'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0007'">
                                <option  :value="$t('White')">{{$t('White')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0008'">
                        <span>{{$t('color')}}：</span>
                        <div class="flex-start">
                            <select id="color-select" ref="colorselect" class="select" v-if="goodsId == 'ACE_0008'">
                                <option  :value="$t('Gray')">{{$t('Gray')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="size flex-start" v-if="uniacid == 20 && goodsId == 'S11'">
                        <span>{{$t('size')}}：</span>
                        <div class="flex-start">
                            <select id="size-select" ref="sizeselect" class="select" v-if="goodsId == 'S11'">
                                <option  :value="$t('small')">{{$t('small')}}</option>
                                <option  :value="$t('medium')">{{$t('medium')}}</option>
                                <option  :value="$t('large')">{{$t('large')}}</option>
                                <option  :value="$t('xl')">{{$t('xl')}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0010'">
                        <input type="hidden" id="color-select" ref="colorselect" class="form-control" value="">
                    </div>

                    <div class="color flex-start" v-if="uniacid == 19 && goodsId == 'ACE_0016'">
                        <input type="hidden" id="color-select" ref="colorselect" class="form-control" value="">
                    </div>

                    <div class="color flex-start" v-if="uniacid != 19">
                        <input type="hidden" id="color-select" ref="colorselect" class="form-control" value="">
                    </div>
                    <!--
                    <div class="color flex-start">
                        <span>{{$t('location')}}：</span>
                        <div class="flex-start">
                            <select id="location-select" ref="locationselect" class="select">
                                <option value="港島">{{$t('Hong Kong Island')}}</option>
                                <option value="九龍">{{$t('Kowloon')}}</option>
                                <option value="新界">{{$t('New Territories')}}</option>
                            </select>
                        </div>
                    </div>-->
                    <div class="buy flex-start">
                        <div class="addcart" @click="addCart">{{$t('Add cart')}} </div>
                        <!--
                        <div class="buythis" @click="orderConfirm">{{$t('Buy')}}</div>-->
                    </div>


                </div>
            </div>
        </div>
        <div class="detail-info" v-if="productInfo">
            <div class="w1200 content">{{$t('Product detail')}}</div>
            <div class="w1200 detail-content" v-html="productInfo.basic.field.detail"></div>
        </div>
        <div class="detail-info" v-if="productInfo">
            <div class="w1200 content">{{$t('Product related')}}</div>
            <div class="w1200" style="overflow-x: auto;">
                <div class="flex-bt">
					<Product2  v-for="(item,index) in leimugoodslist" :key="index" :product="item" />
				</div>
                
            </div>
        </div>
    </div>
</template>
<script>
import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import Search from '@/components/Search';
import Product2 from '@/components/Product2';
// import productDetails from './productDetails.json';
import productDetails from '@/assets/productDetails.json';
export default {
    name: 'Product',
    components: { Search, Product2 },
    data() {
        return {
            uniacid: parseInt(window.localStorage.getItem('uniacid')),
            webset: [],
            leimugoodslist: [],
            productInfo: null,
            goodsId: '',
            detail: '',
            goods: '',
            number: 1,
            color: '',
            size: '',
            location: '',
            goods_sku_key: '',
            locale: window.localStorage.getItem('locale') || this.$i18n.locale,
            currency: window.localStorage.getItem('currency'),
            productDetails: productDetails
        };
    },
    created() {
        this.goodsId = this.$route.query.id;
        const ss = window.localStorage.getItem('webinfo');
        const ssarray = JSON.parse(ss);

        this.webset = ssarray;
        this.uniacid = ssarray['ucid'];

        this.getGoodsDetail();
        this.getGoodsListT("Luggage", "");
    },
    computed: {
        productNameAndDetails() {
            if (this.productInfo && this.productInfo.basic && this.productInfo.basic.field) {
                const uniacid = this.uniacid;
                const goodsId = this.productInfo.basic.field.goods_id;

                if (this.productDetails[uniacid] && this.productDetails[uniacid][goodsId]) {
                    return this.productDetails[uniacid][goodsId];
                }
            }
            return null;  // Return null if no matching product details found
        }
    },
    methods: {
        // 下单
        orderConfirm() {
            this.onConfirmClick('buy');
        },

        async onConfirmClick(code) {
            const res = await this.$http.post('/cart/add', {
                size: this.$refs.sizeselect.value,
                user_id: window.localStorage.getItem('user_id'),
                goods_id: this.goodsId,
                goods_sku_key: this.productInfo.sku.list[0].goods_sku_key,
                goods_number: this.number,
                color: this.$refs.colorselect.value,
                location: this.$refs.locationselect.value,
                promotion_code: this.productInfo.basic.field.promotion_code,
                is_checked: 1,
                is_direct: code === 'buy' ? 1 : 0
            });

            if (res.code !== 0) {
                return this.$message.error(res.message);
            } else {
                if (code === 'buy') {
                    this.$router.push({ name: 'orderConfirm', params: { cart_id: res.data.cart_id } });
                }
            }
        },

        // 修改数量
        changeNumber(type) {
            if (type === 'add') {
                this.number += 1;
            } else {
                this.number > 1 && (this.number -= 1);
            }
        },

        // 获取商品信息
        async getGoodsDetail() {
            if (!this.goodsId) return;
            const res = await this.$http.get('/goods/detail', {
                goods_id: this.goodsId
            });

            const data = res.data;
            data.basic.field.detail_mobile = JSON.parse(data.basic.field.detail_mobile);
            this.detail = data.basic.field.detail;
            this.goods = data.basic.field;
            this.productInfo = res.data;

            const uniacid = this.uniacid;

            if (this.locale === 'zh-CN') {
                const goods_name = chineseLanguageLoader(this.productInfo.basic.field.goods_name, { language: 'zh-CN' });
                this.productInfo.basic.field.goods_name = goods_name;

                const detail = chineseLanguageLoader(this.productInfo.basic.field.detail, { language: 'zh-CN' });
                this.productInfo.basic.field.detail = detail;
            } else if (this.locale === 'EN') {
                const productDetails = this.productNameAndDetails;
                if (productDetails) {  // Ensure productDetails are defined
                    this.productInfo.basic.field.goods_name = productDetails.name;
                    this.productInfo.basic.field.detail = productDetails.detail;
                }
            }
        },

        // 添加到购物车
        async addCart(code) {
    let selectedsize = '';

    if (this.uniacid === 20 && this.goodsId === 'S11') {
        selectedsize = this.$refs.sizeselect.value.toString();
    }

    const res = await this.$http.post('/cart/add', {
        user_id: window.localStorage.getItem('user_id'),
        goods_id: this.goodsId,
        goods_sku_key: this.productInfo.sku.list[0].goods_sku_key,
        goods_number: this.number,
        size: selectedsize,
        color: this.$refs.colorselect.value.toString(),
        promotion_code: this.productInfo.promotion_code,
        is_checked: 1,
        is_direct: code === 'buy' ? 1 : 0
    });

    if (res.code !== 0) {
        if (res.data.code === 401) {
            window.location.href = '/#/login';
            return false;
        }


        this.$store.dispatch('global/handleMessage', { message: res.message });

    } else {
        let successMessage = '添加成功';

        // Translate success message to English if locale is EN
        if (this.locale.toUpperCase() === 'EN') {
            successMessage = 'Added successfully';
        }

        // Display the translated or original success message
        this.$message({ message: successMessage, type: 'success' });
    }
},


        async getGoodsListT(one, two) {
            switch (this.uniacid) {
                case 4: one = "A11"; break;
                case 5: one = "none"; break;
                case 6: one = "Curtain rod"; break;
                case 7: one = "AB"; break;
                case 8: one = "warehouse"; break;
                case 9: one = "jiankang"; break;
                case 10: one = "YTOFFICE"; break;
                case 11: one = "無綫連接儀錶 ( 外置藍牙模組 )"; break;
                case 12: one = "15g Tube"; break;
                case 14: one = "Rake"; break;
                case 15: one = "消毒儀器"; break;
                case 16: one = "新產品"; break;
                case 17: one = "專業牙刷系列"; break;
                case 18: one = "Toy Car"; break;
                case 19: one = "Discount item"; break;
                case 20: one = "課程"; break;
                case 21: one = "Insurance Products"; break;
                case 22: one = "Custom-made"; break;
                case 23: one = "tea"; break;
                case 24: one = "Storage"; break;
                case 25: one = "小電器"; break;
                case 26: one = "Grain Moisture Meter"; break;
                case 27: one = "Insurance Services"; break;
                case 28: one = "Financial Advisory Services"; break;
                case 29: one = "London"; break;
                case 30: one = "Earphones"; break;
                case 31: one = "原創麵譜"; break;
                default: one = "none"; break;
            }
            const res = await this.$http.get('/goods', {
                page: 0,
                size: 5,
                category_one: one,
                category_two: two,
            });
            console.log(res);

            this.isLoading = false;
            if (res.code !== 0) return;
            this.leimugoodslist = res.data.list;

            console.log("leimugoodslist: " + this.uniacid);
            console.log(this.leimugoodslist);
        },
    }
};
</script>

<style lang="scss" scoped>
    .product-wrapper{
        .product-info{
            padding-top: 50px;
            .content{
                display: flex;
                justify-content: space-between;
                @media (min-width:320px)  { flex-direction: column; }
                @media (min-width:481px)  { flex-direction: column; }
                @media (min-width:641px)  { flex-direction: column; }
                @media (min-width:961px)  { flex-direction: column; }
                @media (min-width:1025px) { flex-direction: row; }
                @media (min-width:1281px) { flex-direction: row; }
                .left{
                    @media (min-width:320px)  { width: 100%; }
                    @media (min-width:481px)  { width: 100%; }
                    @media (min-width:641px)  { width: 100%; }
                    @media (min-width:961px)  { width: 100%; }
                    @media (min-width:1025px) { width: 500px; }
                    @media (min-width:1281px) { width: 500px; }
                    
                    height: 500px;
                    .bannerimg{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .right{
                    flex: 1;
                    h6{
                        font-size: 30px;
                    }
                    .buy{
                        margin-top: 20px;
                        .addcart{
                            width: 158px;
                            height: 50px;
                            border: 1px solid #e93323;
                            border-radius: 4px;
                            font-size: 16px;
                            color: #e93323;
                            color: #fff;
                            background-color: #e93323;
                            text-align: center;
                            line-height: 50px;
                            cursor: pointer;
                        }
                        .buythis{
                            width: 158px;
                            height: 50px;
                            border: 1px solid #e93323;
                            border-radius: 4px;
                            font-size: 16px;
                            color: #e93323;
                            text-align: center;
                            line-height: 50px;
                            cursor: pointer;
                            margin-left: 20px;
                        }
                    }
                    .number{
                        width: 300px;
                        height: 50px;
                        margin-top: 20px;
                        .box{
                            // display: inline-block;
                            border: 1px solid #d3d3d3;
                            font-size: 0;
                            .reduce{
                                width: 44px;
                                height: 36px;
                                border: none;
                                background: none;
                                outline: none;
                                font-weight: inherit;
                                font-size: 12px;
                                font-family: inherit;
                                color: #707070;
                                vertical-align: middle;
                                display: block;
                                font-size: 30px;
                                text-align: center;
                                line-height: 32px;
                                cursor: pointer;
                            }
                            .input{
                                width: 64px;
                                height: 36px;
                                border: 1px solid #d3d3d3;
                                border-top: none;
                                border-bottom: none;
                                outline: none;
                                font-weight: inherit;
                                font-size: 20px;
                                text-align: center;
                                line-height: 36px;
                                font-family: inherit;
                                text-align: center;
                                color: #5a5a5a;
                            }
                            .add{
                                width: 44px;
                                height: 36px;
                                border: none;
                                background: none;
                                outline: none;
                                font-weight: inherit;
                                font-size: 12px;
                                font-family: inherit;
                                color: #707070;
                                display: block;
                                text-align: center;
                                line-height: 32px;
                                display: block;
                                font-size: 30px;
                                cursor: pointer;
                                // vertical-align: middle;
                            }
                        }
                    }
                    .color{
                        width: 300px;
                        height: 50px;
                        .select {
                            width: 80px;
                            border: 1px solid #d3d3d3;
                            height: 36px;
                            background: none;
                            outline: none;
                            font-weight: inherit;
                            font-size: 20px;
                            font-family: inherit;
                            vertical-align: middle;
                            display: block;
                            text-align: center;
                            line-height: 32px;
                            cursor: pointer;
                            color: #5a5a5a;
                        }
                        
                        .box{
                            // display: inline-block;
                            border: 1px solid #d3d3d3;
                            font-size: 0;
                            .reduce{
                                width: 44px;
                                height: 36px;
                                border: none;
                                background: none;
                                outline: none;
                                font-weight: inherit;
                                font-size: 12px;
                                font-family: inherit;
                                color: #707070;
                                vertical-align: middle;
                                display: block;
                                font-size: 30px;
                                text-align: center;
                                line-height: 32px;
                                cursor: pointer;
                            }
                            .input{
                                width: 64px;
                                height: 36px;
                                border: 1px solid #d3d3d3;
                                border-top: none;
                                border-bottom: none;
                                outline: none;
                                font-weight: inherit;
                                font-size: 20px;
                                text-align: center;
                                line-height: 36px;
                                font-family: inherit;
                                text-align: center;
                                color: #5a5a5a;
                            }
                            .add{
                                width: 44px;
                                height: 36px;
                                border: none;
                                background: none;
                                outline: none;
                                font-weight: inherit;
                                font-size: 12px;
                                font-family: inherit;
                                color: #707070;
                                display: block;
                                text-align: center;
                                line-height: 32px;
                                display: block;
                                font-size: 30px;
                                cursor: pointer;
                                // vertical-align: middle;
                            }
                        }
                    }
                    .size{
                        width: 300px;
                        height: 50px;
                        .select {
                            width: 80px;
                            border: 1px solid #d3d3d3;
                            height: 36px;
                            background: none;
                            outline: none;
                            font-weight: inherit;
                            font-size: 20px;
                            font-family: inherit;
                            vertical-align: middle;
                            display: block;
                            text-align: center;
                            line-height: 32px;
                            cursor: pointer;
                            color: #5a5a5a;
                        }
                        
                        .box{
                            // display: inline-block;
                            border: 1px solid #d3d3d3;
                            font-size: 0;
                            .reduce{
                                width: 44px;
                                height: 36px;
                                border: none;
                                background: none;
                                outline: none;
                                font-weight: inherit;
                                font-size: 12px;
                                font-family: inherit;
                                color: #707070;
                                vertical-align: middle;
                                display: block;
                                font-size: 30px;
                                text-align: center;
                                line-height: 32px;
                                cursor: pointer;
                            }
                            .input{
                                width: 64px;
                                height: 36px;
                                border: 1px solid #d3d3d3;
                                border-top: none;
                                border-bottom: none;
                                outline: none;
                                font-weight: inherit;
                                font-size: 20px;
                                text-align: center;
                                line-height: 36px;
                                font-family: inherit;
                                text-align: center;
                                color: #5a5a5a;
                            }
                            .add{
                                width: 44px;
                                height: 36px;
                                border: none;
                                background: none;
                                outline: none;
                                font-weight: inherit;
                                font-size: 12px;
                                font-family: inherit;
                                color: #707070;
                                display: block;
                                text-align: center;
                                line-height: 32px;
                                display: block;
                                font-size: 30px;
                                cursor: pointer;
                                // vertical-align: middle;
                            }
                        }
                    }
                    .price{
                        width: 100%;
                        height: 100px;
                        margin-top: 20px;
                        background: url('~@/assets/images/money-back.png') no-repeat center center /100% 100%;
                        box-sizing: border-box;
                        padding: 0 20px;
                        span:nth-child(1){
                            font-size: 50px;
                            font-weight: bold;
                            color: #ffffff;
                        }
                        span:nth-child(2) {
                        color: #ffffff;
                        text-decoration: line-through;
                        padding-top: 28px;
                        margin-left: 6px;
                    }
                    }
                }
            }
        }
        .detail-info{
            .content{
                background-color: #ededed;
                height: 60px;
                margin-top: 30px;
                box-sizing: border-box;
                padding: 0 20px;
                color: #e93323;
                font-size: 20px;
                text-align: center;
                line-height: 60px;
            }
            .detail-content img{
                    width: 100%;
                    display: block;
                }
            
        }
    }
</style>