<template>
	<div class="home-wrapper">
			<search :logo="webset.logo" leimulist="topmenu" />
		<!-- banner -->
		<div class="banner w1200">
			<el-carousel trigger="click" height="400px">
				<el-carousel-item v-for="(item,index) in banner" :key="index">
					<img :src="item.image" alt="">
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- product -->
		<div class="news w1200">
<div class="header">
      <span class="home"><nuxt-link class="home" to="/">{{$t('index')}}  </nuxt-link> >
	   <!-- <nuxt-link class="home" to="/news_list">新聞資訊</nuxt-link> -->
	   > </span>{{newsDetail.title}}
    </div>
<div class="news_count">
      <div class="title">{{newsDetail.title}}</div>
      <div class="time">
        <div class="time-list"><span class="iconfont icon-shijian1"></span>  {{newsDetail.create_time}}</div>
      </div>
      <div style="text-align: left;" class="content detail-content">
        <div
          v-if="newsDetail.content"
          class="detail-html"
          v-html="newsDetail.html"
        ></div>
      </div>
    </div>


   </div>

	</div>
</template>

<script>
import Search from '@/components/Search'
import Product from '@/components/Product'
export default {
    name: 'Home',
    components: {Search, Product},
	data() {
		return {
			webset:[],
		 
			id:0,newsDetail:[],
			moduleList: [],
			newslist: [],
			leimugoodslist: [],
			banner: [],
			menu: {
				active: '',
				list: []
			},
			leimulist:[],
			size: 10,
			page: 1,
			pageCount: 0,
			goodsList: [],
		}
	},
	created() {	
		 this.getuniacid()
		this.id = this.$route.query.id
		this.getarticle()
		this.getHomeLayout()
	 
	
	},
 
	methods: {
		// 获取首頁模块
		async getuniacid(){
			// this.uniacid= 4 ;
			 var ss = window.localStorage.getItem('webinfo');
			 if(!ss){
				 var url = window.location.href;
				 var dz_url = url.split("#")[0];
				 var www_dz_url = dz_url.split("/")[2];
				 			
				
				var centerdomain= www_dz_url.split(".");
				console.log('centerdomain:',centerdomain);
				let res
				if(centerdomain[1]=='khshop'){
					res = await this.$http.get('app.layout/weblist',{centerdomain:centerdomain[0]})
					console.log('websiteinfo:',res);
				}else{
					 res = await this.$http.get('app.layout/weblist',{siteroot:www_dz_url})
					console.log('websiteinfo:',res);
				}
				
				 
				 console.log('websiteinfo:',res);
				 ss = JSON.stringify(res[0])		
		
			 }
			 var ssarray = JSON.parse(ss)
			 console.log('ss',ss);
			 console.log('ssarray.ucid',ssarray['ucid']);
			 this.webset =  ssarray
			 this.uniacid = ssarray['ucid']
			 window.localStorage.setItem('uniacid',this.uniacid)
			 document.title = ssarray.webtitle
			// this.getwebset()
			 this.getleimugoods()
		},
		
		//获取新闻列表
		async getarticle(){
			 console.log('id:',this.id);
			 	if (this.id.indexOf('ttp') > -1) {
					console.log('go url');
					this.id = this.id.replace('/pages/webview/webview?url=','' )
					this.id = this.id.replace('%3A',':')
					this.id = this.id.replace('%2F','/')
					this.id = this.id.replace('%2F','/')
					this.id = this.id.replace('%2F','/')
					this.id = this.id.replace('%3D','=')
					this.id = this.id.replace('%3F','?')
				 
					console.log('id23:',this.id);
				     window.location.href= this.id
					// window.open(this.id,'_blank')
					return 
					}
			let res = await this.$http.get('/app.layout/article?id='+this.id,{})
				console.log('news',res);
			if(res){
			
				  this.newsDetail = res[0]
				  this.banner=res[0]['banner']
				  document.title = res[0]['title']
				}
			}
		,
		// 获取首頁模块
	async getHomeLayout() {
			let res = await this.$http.get('/app.layout', {
				url: '/pages/tabbar/index/index',uniacid:this.uniacid
			})
			if (res.code !== 0) return
			this.moduleList = res.data.list
			this.banner = res.data.list.reduce((arr,item)=>{
				item.type == 'carousel' && (arr = item.data)
				return arr
			},[])
			console.log('banner',this.banner);
		},

 
 

	 
	}
}
</script>
<style lang="scss" scoped>
	.home-wrapper{
		.banner{
			height: 400px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.product-list{
			margin: 10px auto;
			margin-top: 50px;
			.main{
				.title{
					font-size: 20px;
					font-weight: bold;
				}
				.content{
					box-sizing: border-box;
					padding: 30px 0;
					flex-wrap: wrap;
					// background: #fff;
				}
				.menu{
					margin-left: 20px;
					.items{
						margin-right: 10px;
						font-weight: 400;
						
						cursor: pointer;
						&.active{
							color: red;
							font-weight: bold;
							border-bottom: 2px solid #e93323;
						}
					}
				}
			}
		}
	}
	.header {
	  margin-top: 21px;
	  color: #999999;
	  .home {
	    color: #282828;
	  }
	}
	.news_count{
	  text-align: center;
	  background: #ffffff;
	  padding: 0 72px;
	  margin-top: 20px;
	  .title{
	    color: #333333;
	    font-size: 24px;
	    padding-top: 44px;
	  }
	  .time{
	    margin-top: 22px;
	    color: #999999;
	    font-size: 13px;
	    .time-list{
	      display: inline-block;
	    }
	  }
	  .content{
	    margin-top: 50px;
	    color: #333333;
	    p, div{
	      margin-top: 40px;
	      line-height: 30px;
	    }
	    img{
	      display: block;
	      margin: 0 auto;
	
	    }
	  }
	}
	 
</style>
