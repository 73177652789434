<template>
    <div class="product-wrapper">	
	    <div style="position: absolute; margin-top:8px;color:#409eff;border: 0 solid #000;
    box-sizing: border-box;">
		  <div v-for="(item,index) in product.prolist2">{{item.detail}}</div>
		</div>
		 <div class="pictrue" @click="detail(product.goods_id)">
            <img :src="product.goods_image" alt="">
	
		
        </div>
        <div class="name" v-html="product.goods_name"></div>

    </div>
</template>
<script>
import chineseLanguageLoader from "chinese-language-loader/lib/main.js";

export default {
  props: ["product"],
  data() {
    return {
      locale: window.localStorage.getItem("locale") || this.$i18n.locale,
    };
  },
  created() {
    const uniacid = window.localStorage.getItem("uniacid");

    if (this.locale === "zh-CN") {
      this.product.goods_name = chineseLanguageLoader(this.product.goods_name, {
        language: "zh-CN",
      });
    } else if (this.locale === "EN") {
      switch (parseInt(uniacid)) {
        case 17:
          this.updateProductNameForUnacid17();
          break;
        case 20:
          this.updateProductNameForUnacid20();
          break;
        case 24:
          this.updateProductNameForUnacid24();
          break;
        case 29:
          this.updateProductNameForUnacid29();
          break;
      }
    }
  },
  methods: {
    detail(id) {
      this.$router.push({ path: "/product", query: { id } });
    },
    updateProductNameForUnacid17() {
      const names = {
        NS_01: "Slim Head Toothbrush",
        NS_02: "Double Layer Bristle Toothbrush",
        NS_03: "Deep Cleansing Toothbrush (Super Soft Bristles)",
        NS_04: "Deep Cleansing Toothbrush (Regular Bristles)",
        NS_05: "Toothbrush (Soft Bristles)",
        NS_06: "Long Handle Interdental Brush - 0.6MM (pack of 6)",
        NS_07: "Long Handle Interdental Brush - 0.7MM (pack of 6)",
        NS_08: "Long Handle Interdental Brush - 0.5MM (pack of 6)",
      };

      this.product.goods_name = names[this.product.goods_id] || this.product.goods_name;
    },
    updateProductNameForUnacid20() {
      const names = {
        UF_001: "Sabre Course 8 Sessions",
        UF_002: "Foil Beginner Class</br> (Primary School)",
        UF_003: "Children's Fencing Course (Ages 3-6) 8 Sessions",
        UF_004: "Adult Fencing Training </br>Class",
      };

      this.product.goods_name = names[this.product.goods_id] || this.product.goods_name;
    },
    updateProductNameForUnacid24() {
      const names = {
        SK_001: "Mini storage for 12 months, size 4x3, 12 square feet",
        SK_002: "Mini storage for 12 months, size 5x3, 15 square feet",
        SK_003: "Mini storage for 12 months, size 4x4, 16 square feet",
        SK_004: "Mini storage for 12 months, size 4x5, 20 square feet",
        SK_005: "Mini storage for 12 months, size 5x6, 30 square feet",
        SK_007: "Smart storage for 12 months, size 4x4, 16 square feet",
      };

      this.product.goods_name = names[this.product.goods_id] || this.product.goods_name;
    },
    updateProductNameForUnacid29() {
      const names = {
        London_01: "London City Island",
        London_02: "Zone 1, London (ZONE 1)",
        London_03: "UPTON GARDENS, London (ZONE 3)",
        London_04: "LONG & WATERSON, London (ZONE 1)",
        London_05: "LON 091",
        London_06: "POSTMARK, London (ZONE 1)",
        London_07: "ZONE 2, London (ZONE 2)",
        London_08: "London (Zone 3)",
        London_09: "CALICO WHARF, London (ZONE 2)",
        London_10: "THE AURIA, London (ZONE 2)",
      };

      this.product.goods_name = names[this.product.goods_id] || this.product.goods_name;
    },
  },
};
</script>
<style lang="scss" scoped>
    .product-wrapper{
        @media (min-width:320px)  { width: 100%; }
        @media (min-width:481px)  { width: 40%; }
        @media (min-width:641px)  { width: 40%; }
        @media (min-width:961px)  { width: 40%; }
        @media (min-width:1025px) { width: 210px; }
        @media (min-width:1281px) { width: 210px; }
        height: 320px;
        background-color: #fff;
        padding: 16px;
        margin: 20px 20px 0 0;
        border-radius: 2px;
        cursor: pointer;
        box-sizing: border-box;
        transition: all .6s;
        position: relative;
        &:hover{
            -webkit-box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
            box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
            -webkit-transform: translate3d(0,-2px,0);
            transform: translate3d(0,-2px,0);
        }
        .pictrue{
            width: 192px;
            height: 192px;
            img{
                width: 92%;
                height: 100%;
            }
        }
        .money{
            margin-top: 12px;
            .font-color{
                font-size: 22px;
                font-weight: 700;
                color: #e93323!important;
            }
            .y_money{
                color: #aaa;
                font-size: 12px;
                text-decoration: line-through;
                margin-left: 8px;
                line-height: 22px;
                height: 12px;
            }
        }
        .name{
            margin-top: 8px;
            color: #5a5a5a;
            height: 37px;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
        }
    }
</style>