<template>
	<div class="home-wrapper">
		<search />
		<!-- banner -->
		<div class="banner w1200">
			<el-carousel trigger="click" height="400px">
				<el-carousel-item v-for="(item,index) in banner" :key="index">
					<img :src="item.image" alt="">
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- product -->
		<div class="news w1200">
  <div class="news-list">
              <div class="list acea-row row-middle" v-if="newslist.length">
                <div class="item" v-for="(item, index) in newslist" :key="index" @click="detail(item.id)">
                  <div class="item-count acea-row">
                    <div class="pictrue"><img :src="'https://os.greenhousecos.com/attachment/'+item.image"></div>
                    <div class="list-content">
                      <div class="name line1">{{item.title}}</div>
                      <div class="info line2">{{item.title}}</div>
                      <div class="time acea-row">
                        <div><span class="iconfont icon-shijian1"></span>  {{item.createDate}}</div>
                        <!-- <div class="visit"><span class="iconfont icon-shijian"></span>  {{item.visit ? item.visit : 0}}</div>-->
                      </div>
                    </div>
                  </div>

                </div>
              </div>
   </div>
   </div>

	</div>
</template>

<script>
import Search from '@/components/Search'
import Product from '@/components/Product'
export default {
    name: 'Home',
    components: {Search, Product},
	data() {
		return {
			moduleList: [],
			newslist: [],
			leimugoodslist: [],
			banner: [],
			menu: {
				active: '',
				list: []
			},
			leimulist:[],
			size: 10,
			page: 1,
			pageCount: 0,
			goodsList: [],
		}
	},
	created() {	
		this.getarticlelist()
		this.getHomeLayout()
	 
	
	},
	methods: {
		detail(id) {
		    this.$router.push({ path:'/detail', query:{id}})
		},
		//获取新闻列表
		async getarticlelist(){
			let res = await this.$http.get('/app.layout/articlelist',{})
				console.log('alist',res);
			if(res){
			
				this.newslist = res
				}
			}
		,
		// 获取首頁模块
		async getHomeLayout() {
			let res = await this.$http.get('/app.layout', {
				url: '/pages/tabbar/index/index'
			})
			if (res.code !== 0) return
			this.moduleList = res.data.list
			this.banner = res.data.list.reduce((arr,item)=>{
				item.type == 'carousel' && (arr = item.data)
				return arr
			},[])
		},

		//获取分类
		async getleimu(){
			let res = await this.$http.get('/app.layout/mulu',{})
			if(res){
				this.menu.list = res.itemtitle.map((item,index)=>{
					let obj = {label: item, id: res.itemcode[index]}
					return  obj
				})
			}
		},
		async getleimugoods(){
			let res = await this.$http.get('/app.layout/getmulugoods',{})
			if(res){
				console.log('gsl',res);
				   this.leimugoodslist = res 
			}
		},
		
		async getleimu1(){
				console.log('mulu1');
				let res = await this.$http.get('/app.layout/mulu1', {
					 	 
				})
					  if(res){
						  console.log(res);
						  this.leimulist = res 
						 
						  console.log('ok');
					  }
				
		},
		async getleimu2(){
				if(uni.getStorageSync('category_one1')){
					this.category_one=uni.getStorageSync('category_one1')
					uni.setStorageSync("category_one1", '')
					console.log('one',this.category_one);
				}
				let res = await uni.$http.get('/app.layout/mulu2', {
					 father_code:this.category_one	 
				})
					  if(res.data){
						  this.leimutitle = res.data
					      console.log(this.leimutitle);
						  console.log('ok:leimu2');
					  }
				
		},

		//点击分类
		async onClickItem(e,f) {
			this.menu.active = e
			this.goodsList=[]
			this.page=0
			//this.getGoodsList()
		},

		//获取产品列表
		async getGoodsListT(one,two) {
			this.menu.active = two
			if (this.page > 1 && this.page - 1 >= this.pageCount) return
			this.isLoading = true
			const res = await this.$http.get('/goods', {
				page: 0,
				size: this.size,
				category_one: one,
				category_two: two,
			})
			console.log('resgoods',res);
			this.isLoading = false
			if (res.code !== 0) return
			let data = res.data
		    this.leimugoodslist[one]['goods']=res.data.list
		
		 
		},
		//获取产品列表
		async getGoodsList(category_two) {
			if (this.page > 1 && this.page - 1 >= this.pageCount) return
			this.isLoading = true
			const res = await this.$http.get('/goods', {
				page: this.page++,
				size: this.size,
				category_one: this.menu.active,
				category_two: category_two,
			})
			console.log('resgoods',res);
			this.isLoading = false
			if (res.code !== 0) return
			let data = res.data
			this.pageCount = Math.ceil(data.total / this.size)
			if (this.page <= 2) this.goodsList = []
			this.goodsList = this.goodsList.concat(data.list)
		},
	}
}
</script>
<style lang="scss" scoped>
	.home-wrapper{
		.banner{
			height: 400px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.product-list{
			margin: 10px auto;
			margin-top: 50px;
			.main{
				.title{
					font-size: 20px;
					font-weight: bold;
				}
				.content{
					box-sizing: border-box;
					padding: 30px 0;
					flex-wrap: wrap;
					// background: #fff;
				}
				.menu{
					margin-left: 20px;
					.items{
						margin-right: 10px;
						font-weight: 400;
						
						cursor: pointer;
						&.active{
							color: red;
							font-weight: bold;
							border-bottom: 2px solid #e93323;
						}
					}
				}
			}
		}
	}
	 .news{
	    .news_count{
	      width: 860px;
	      margin: 20px auto 0;
	      background: #ffffff;
	      border-radius: 8px;
	      padding-bottom: 90px;
	    }
	    .swiper-container{
	      height: 68px;
	    }
	    .swiper-slide{
	      max-width: 100px;
	      margin: 0 10px;
	      text-align: center;
	      cursor: pointer;
	      span{
	        display: inline-block;
	        max-width: 100px;
	      }
	      &.on{
	        color: #E93323;
	        position: relative;
	        &::after{
	          content: "";
	          display: inline-block;
	          width: 100%;
	          height: 3px;
	          background: #E93323;
	          position: absolute;
	          left: 0;
	          bottom: 0;
	        }
	      }
	    }
	    .cate_list{
	      height: 68px;
	      line-height: 68px;
	      border-bottom: 1px solid #E1E1E1;
	      overflow: hidden;
	      .list_count{
	        display: inline-block;
	        width: 10000px;
	      }
	    }
	    .list{
	      width: 100%;
	      .item{
	        padding: 0 30px;
	        width: 100%;
	        cursor: pointer;
	        &:hover{
	          box-shadow: 0 2px 15px rgba(79, 109, 143, 0.15);
	        }
	        .item-count{
	          padding: 40px 0;
	          border-bottom: 1px solid #E2E2E2;
	        }
	        &:last-child{
	          .item-count{
	            border: none;
	          }
	        }
	        .pictrue{
	          width: 260px;
	          height: 160px;
	          img{
	            width: 100%;
	            height: 100%;
	          }
	        }
	      .list-content{
	        margin-left: 30px;
	        color: #282828;
	        width: 500px;
	        position: relative;
	        .name{
	          font-size: 18px;
	          font-weight: bold;
	          line-height: 30px;
	        }
	        .info{
	          margin-top: 15px;
	          font-size: 16px;
	        }
	        .time{
	          margin-top: 20px;
	          font-size: 14px;
	          color: #999999;
	          position: absolute;
	          bottom: 10px;
	        }
	        .visit{
	          margin-left: 20px;
	        }
	      }
	      }
	    }
	    .news_rt{
	      width: 320px;
	      background: #fff;
	      padding: 0 35px 20px;
	      border-radius: 8px;
	      margin-top: 20px;
	      .title{
	        text-align: center;
	        font-size: 18px;
	        padding: 19px 0;
	        border-bottom: 1px dashed #F1F1F1;
	      }
	      .latest-list{
	        margin-top: 25px;
	        color: #666666;
	        font-size: 14px;
	        .number{
	          display: inline-block;
	          width: 16px;
	          height: 20px;
	          margin-right: 10px;
	          font-size: 11px;
	          background: url("~@/assets/images/newsIcon.png") 100% center;
	          text-align: center;
	          line-height: 20px;
	          transition: all linear .2s;
	        }
	        .list{
	          display: inline-block;
	          width: 223px;
	        }
	        &:hover{
	          .number{
	            background: url("~@/assets/images/newsIcon_red.png") 100% center;
	            color: #ffffff;
	          }
	          .list{
	            color: #E93323;
	          }
	        }
	      }
	    }
	    .noCart{
	      text-align: center;
	      margin-bottom: 70px;
	      .pictrue{
	        width: 216px;
	        height: 136px;
	        margin: 0 auto;
	        img{
	          width: 100%;
	          height: 100%;
	        }
	        .tip{
	          margin-top: 12px;
	          color: #646464;
	        }
	      }
	    }
	  }
</style>
